<template>
  <transition name="fade">
    <div class="post-overlay" @click="hideOverlay">
      <div class="Overlay-closeBtn">
        <v-icon dark>mdi-close</v-icon>
      </div>

      <div class="overlay-content" id="overlay-content">
        <!-- Header -->
        <v-container fluid>
          <template v-if="fetch_loading">

            <template v-if="$vuetify.breakpoint.xsOnly">
              <v-row>
                <v-col md="9" cols="12" align-self="center">
                  <v-btn fab small class="mx-3 mb-2 mr-5" @click="hideOverlay_dir">
                    <v-icon>mdi-keyboard-return</v-icon>
                  </v-btn>
                  <span class="text-h6 font-weight-bold" style="position: relative;top: -1px;">{{ loiData.loi_title }}</span>
                </v-col>

                <v-col align-self="center" cols="2">
                  <loi-liker
                      :theme="1"
                      :loi_id="loiData.id"
                      :like_count="loiData.like_count"
                      :is_liked="loiData.liked"
                  ></loi-liker>
                </v-col>

                <v-col cols="10" align-self="center">
                  <!-- downloader -->
                  <loi-downloader
                      :theme="1"
                      :loi_id="loiData.id"
                      :open_key="loiData.open_key"
                      :download_count="loiData.download_count"
                      :vip_requirement="loiData.vip_requirement || 1"
                      :is_block="$vuetify.breakpoint.xsOnly"
                      @download_increase="download_increase"
                  ></loi-downloader>
                </v-col>

              </v-row>

              <v-row no-gutters class="mt-2 mb-3" v-if="loiData.online_play === 1">
                <v-col cols="12">
                  <LoiOP :theme="1" :loi_title="loiData.loi_title" :loi_id="loiData.id" :online_play="loiData.online_play" :vip_requirement="loiData.vip_requirement || 1"></LoiOP>
                </v-col>
              </v-row>

            </template>
            <v-row v-else>
              <v-col align-self="center" md="1" cols="3">
                <loi-liker
                    :theme="1"
                    :loi_id="loiData.id"
                    :like_count="loiData.like_count"
                    :is_liked="loiData.liked"
                ></loi-liker>
              </v-col>
              <v-col :md="downloaderMdCol_title" cols="9" align-self="center">
                <span class="headline">{{ loiData.loi_title }}</span>
              </v-col>
              <v-col :md="downloaderMdCol_dl" cols="12" align-self="center">
                <v-row no-gutters :style="downloaderStyle">
                  <v-col cols="12" md="11" v-if="!$vuetify.breakpoint.xsOnly && loiData.online_play === 1">
                    <LoiOP :theme="2" :loi_title="loiData.loi_title" :loi_id="loiData.id" :online_play="loiData.online_play" :vip_requirement="loiData.vip_requirement || 1"></LoiOP>
                  </v-col>
                  <v-col cols="12" md="1">
                    <!-- downloader -->
                    <loi-downloader
                        :theme="downloaderTheme"
                        :loi_id="loiData.id"
                        :open_key="loiData.open_key"
                        :download_count="loiData.download_count"
                        :vip_requirement="loiData.vip_requirement || 1"
                        :is_block="$vuetify.breakpoint.xsOnly"
                        @download_increase="download_increase"
                    ></loi-downloader>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </template>
          <template v-else>
            <div class="d-flex flex-row justify-space-between pa-2">
              <v-skeleton-loader
                  :width="$vuetify.breakpoint.smAndUp ? '100' : '200'"
                  type="avatar"
                  class="ml-3"
              ></v-skeleton-loader>
              <v-skeleton-loader
                  :width="$vuetify.breakpoint.smAndUp ? '1000' : '700'"
                  type="heading"
                  class="align-self-center"
              ></v-skeleton-loader>
              <v-skeleton-loader
                  :width="$vuetify.breakpoint.smAndUp ? '100' : '200'"
                  type="button"
                  class="align-self-center"
              ></v-skeleton-loader>
            </div>
          </template>
        </v-container>

        <!-- preview -->

        <v-row no-gutters>
          <v-col cols="12">

            <VueSlickCarousel ref="c1" :asNavFor="$refs.c2"  :focusOnSelect="true" :arrows="showArrows" :dots="true" :touchThreshold="12" class="mb-6" v-if="loiData.images">
              <div v-for="thumb in loiData.images" :key="thumb">
                <v-img
                    :src="thumb"
                    :lazy-src="thumb"
                    max-width="100%"
                    :aspect-ratio="8 / 6"
                    class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </VueSlickCarousel>
          </v-col>

        </v-row>



        <!-- thumb -->
        <v-container fluid style="background: #fff" v-show="fetch_loading" class="mt-2">
          <VueSlickCarousel
              ref="c2"
              :asNavFor="$refs.c1"
              :slidesToShow="6"
              :focusOnSelect="true"
              :arrows="false"
              v-if="loiData.images"
          >
            <div v-for="thumb in loiData.images" :key="thumb" class="px-2">
              <v-card
                  class="thumb"
              >
                <v-img
                    :src="thumb"
                    :lazy-src="thumb"
                    aspect-ratio="1.4"
                    max-width="126px"
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </div>
          </VueSlickCarousel>


        </v-container>

        <!-- info -->
        <v-container
            class="pt-0"
            :class="{
            'px-0': $vuetify.breakpoint.xsOnly,
            'px-6': $vuetify.breakpoint.smAndUp,
          }"
        >
          <v-row v-if="fetch_loading">
            <v-col cols="12" md="8">
              <v-row class="flex-column">
                <v-col>
                  <v-card
                      color="white px-5 py-6"
                      :tile="$vuetify.breakpoint.xsOnly"
                  >
                    <v-icon class="mr-1" style="transform: translateY(-5px)"
                    >mdi-format-quote-open</v-icon
                    >
                    <span
                        class="subtitle-2"
                        v-text="loiData.loi_description"
                    ></span>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card :tile="$vuetify.breakpoint.xsOnly">
                    <v-container>
                      <v-row
                          justify="center"
                          class="my-6"
                          v-if="!$store.getters.token"
                      >
                        <v-btn
                            @click="$store.commit('showAccountDialog')"
                            class="px-6"
                            color="primary"
                            dark
                            v-if="!$store.getters.token"
                        >
                          <v-icon class="mr-1">mdi-account-circle</v-icon
                          >登录以加入对话
                        </v-btn>
                      </v-row>

                      <div class="px-4 pb-3" v-else>
                        <v-row>
                          <v-col cols="12" class="mt-2">
                            <v-text-field
                                prepend-inner-icon="mdi-chat-processing-outline"
                                v-model="comment.content"
                                label="说点什么..."
                                @click="showComment = true"
                                single-line
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <div v-show="showComment">
                          <v-row class="d-flex justify-space-between">
                            <v-col cols="5" md="4">
                              <v-select
                                  :items="['👍 推荐', '👎 不推荐']"
                                  label="观点"
                                  v-model="comment.author_point"
                              ></v-select>
                            </v-col>

                            <v-col
                                cols="2"
                                align-self="center"
                                class="d-flex justify-end"
                            >
                              <v-btn text @click="clearComment">取消</v-btn>
                              <v-btn
                                  color="primary"
                                  @click="postComment"
                                  :loading="comment_loading"
                                  :disabled="
                                  !comment.author_point ||
                                  !comment.content ||
                                  comment_loading
                                "
                                  class="ml-3"
                              >发表</v-btn
                              >
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card
                      color="white"
                      v-show="list.length != 0"
                      :tile="$vuetify.breakpoint.xsOnly"
                  >
                    <v-list two-line subheader>
                      <v-list-item v-show="list.length != 0">
                        <span
                            class="subheading font-weight-medium ml-2"
                            v-text="`${total_comments} 条评论`"
                        ></span>

                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" class="ml-1">
                              <v-icon>mdi-sort-variant</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="sortByPopular">
                              <v-list-item-title>热门评论</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="sortByLatest">
                              <v-list-item-title>最新评论</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>

                        <v-spacer></v-spacer>
                        <v-tooltip
                            top
                            :color="review_summary_color"
                            v-if="showSummary"
                        >
                          <template v-slot:activator="{ on }">
                            <v-chip
                                small
                                label
                                :color="review_summary_color"
                                dark
                                outlined
                                v-on="on"
                                class="font-weight-bold"
                            >{{ review_summary }}</v-chip
                            >
                          </template>
                          <span
                              v-text="
                              `${total_comments} 条评论中有 ${positivePtg}% 为推荐`
                            "
                          ></span>
                        </v-tooltip>
                      </v-list-item>
                      <v-divider class="mb-3"></v-divider>
                      <v-slide-y-reverse-transition group>
                        <v-list-item
                            v-for="(comment, index) in list"
                            :key="index"
                        >
                          <v-tooltip
                              left
                              :color="
                              comment.author_point == 1 ? '#42A5F5' : '#EF5350'
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-list-item-avatar v-on="on">
                                <v-icon
                                    :class="[
                                    comment.author_point == 1
                                      ? 'blue lighten-1'
                                      : 'red lighten-1',
                                  ]"
                                    class="white--text"
                                >{{
                                    comment.author_point == 1
                                        ? "mdi-thumb-up"
                                        : "mdi-thumb-down"
                                  }}</v-icon
                                >
                              </v-list-item-avatar>
                            </template>
                            <span>{{
                                comment.author_point == 1
                                    ? "😍 推荐"
                                    : "🙁 不推荐"
                              }}</span>
                          </v-tooltip>

                          <v-list-item-content>
                            <v-list-item-title>
                              <v-chip small label class="mr-2 px-2" v-if="comment.own">我</v-chip>
                              <span class="body-2">{{ comment.author }}</span>
                              <v-chip small class="ml-2 white--text px-2" style="height: 22px;" :color="vipList[comment.user_vip_id].color">
                                {{vipList[comment.user_vip_id].title}}
                              </v-chip>
                              <span
                                  class="
                                  body-2
                                  ml-2
                                  mr-1
                                  grey--text
                                  text--darken-1
                                "
                              >{{ formatTime(comment.date) }}</span>
                              <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                      x-small
                                      text
                                      fab
                                      v-if="comment.own"
                                      style="margin-bottom: 1px"
                                      v-on="on"
                                  >
                                    <v-icon small>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>
                                <v-list width="120" dense>
                                  <v-list-item
                                      @click="handle_deleteComment(index)"
                                  >
                                    <v-list-item-icon class="mr-4">
                                      <v-icon>mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title
                                        class="subtitle-1"
                                        style="margin-top: 1px"
                                    >删除</v-list-item-title
                                    >
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-list-item-title>
                            <span
                                class="body-1"
                            >
                              <v-chip outlined label small v-if="comment.user_svip === 1" color="#7776B3">
                                <v-icon class="mr-1">mdi-bus-articulated-front</v-icon>巴士会</v-chip>
                              {{comment.content}}</span>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn
                                icon
                                ripple
                                small
                                @click="handle_comment_like(comment.id, index)"
                                :loading="comment.like_loading"
                                :disabled="comment.like_loading"
                            >
                              <v-icon
                                  :color="
                                  comment.liked ? 'like' : 'grey lighten-1'
                                "
                              >{{
                                  comment.liked
                                      ? "mdi-heart"
                                      : "mdi-heart-outline"
                                }}</v-icon
                              >
                            </v-btn>
                          </v-list-item-action>
                          <span
                              class="subtitle-1 ml-2"
                              v-text="comment.like_number"
                          ></span>
                        </v-list-item>
                      </v-slide-y-reverse-transition>
                    </v-list>
                  </v-card>
                  <!-- 无限加载 -->
                  <infinite-loading
                      :identifier="infiniteId"
                      @infinite="infiniteHandler"
                  >
                    <div slot="spinner">
                      <v-container>
                        <v-row justify="center" class="mt-5">
                          <v-progress-circular
                              color="#918D89"
                              indeterminate
                              :size="30"
                              :width="3"
                          ></v-progress-circular>
                        </v-row>
                      </v-container>
                    </div>
                    <div slot="no-more"></div>
                    <div slot="no-results" class="my-10">
                      <v-img
                          aspect-ratio="1"
                          width="100"
                          height="100"
                          class="mx-auto mb-5"
                          src="https://static.moegoat.com/images/comment-empty.svg"
                      ></v-img>
                      <span>开始聊天吧 😊</span>
                    </div>
                    <div slot="error" slot-scope="{ trigger }" class="my-5">
                      ⚠️ 加载失败 , 请
                      <v-btn @click="trigger">重新加载</v-btn>
                    </div>
                  </infinite-loading>
                </v-col>
              </v-row>
            </v-col>


            <v-col cols="12" md="4" :class="{'mt-0 pt-0': $vuetify.breakpoint.xsOnly}">
              <v-row class="flex-column" :no-gutters="$vuetify.breakpoint.xsOnly">
                <v-col>
                  <v-card color="white" :tile="$vuetify.breakpoint.xsOnly">
                    <v-list subheader class="pb-0" style="cursor: pointer">
                      <!-- 分类 -->
                      <v-hover v-slot:default="{ hover }" :close-delay="200">
                        <div>
                          <v-list-item
                              @click="handleToPage"
                              @mouseover="getCategoryInfo"
                              :ripple="false"
                          >
                            <v-list-item-icon>
                              <v-icon class="mt-1">mdi-face-woman</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                  loiData.category.name
                                }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-slide-x-transition>
                            <v-sheet
                                elevation="10"
                                v-show="hover"
                                style="
                                position: absolute;
                                right: -375px;
                                top: 0;
                                width: 360px;
                                height: 285px;
                              "
                            >
                              <template v-if="category_info.name">
                                <v-img
                                    gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)"
                                    height="130"
                                    :lazy-src="category_info.cover"
                                    :src="category_info.cover"
                                    class="align-end"
                                >
                                  <h3
                                      class="ma-3 white--text"
                                      @click="handleToPage"
                                  >
                                    {{ category_info.name }}
                                  </h3>
                                </v-img>

                                <v-row class="text-center my-2">
                                  <v-col cols="6" class="d-flex flex-column">
                                    <span
                                        class="grey--text text--darken-2"
                                        @click="handleToPage"
                                    >资源数</span
                                    >
                                    <span
                                        class="title font-weight-bold"
                                        @click="handleToPage"
                                    >{{ category_info.count }}</span
                                    >
                                  </v-col>
                                  <v-col cols="6" class="d-flex flex-column">
                                    <span
                                        class="grey--text text--darken-2"
                                        @click="handleToPage"
                                    >关注者</span
                                    >
                                    <span
                                        class="title font-weight-bold"
                                        @click="handleToPage"
                                    >{{ category_info.following }}</span
                                    >
                                  </v-col>
                                </v-row>

                                <v-row justify="center">
                                  <v-hover
                                      v-slot:default="{ hover }"
                                      v-if="category_info.subscribed"
                                  >
                                    <v-btn
                                        :color="hover ? '#757575' : 'bpink'"
                                        tile
                                        width="250px"
                                        @click="handle_subscribe"
                                        :disabled="subscribe_loading"
                                        :loading="subscribe_loading"
                                        class="white--text"
                                    >
                                      <template v-if="hover">
                                        <v-icon class="mr-1"
                                        >mdi-playlist-remove</v-icon
                                        >取消订阅
                                      </template>
                                      <template v-else>
                                        <v-icon class="mr-1"
                                        >mdi-playlist-check</v-icon
                                        >
                                        已订阅({{ category_info.following }})
                                      </template>
                                    </v-btn>
                                  </v-hover>
                                  <v-btn
                                      width="250px"
                                      color="primary"
                                      tile
                                      @click="handle_subscribe"
                                      :loading="subscribe_loading"
                                      :disabled="subscribe_loading"
                                      v-else
                                  >
                                    <v-icon class="mr-1"
                                    >mdi-playlist-plus</v-icon
                                    >
                                    订阅({{ category_info.following }})
                                  </v-btn>
                                </v-row>
                              </template>
                              <template v-else>
                                <v-row
                                    justify="center"
                                    align="center"
                                    class="fill-height"
                                >
                                  <v-progress-circular
                                      indeterminate
                                      color="primary"
                                      :size="50"
                                      :width="4"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-sheet>
                          </v-slide-x-transition>
                        </div>
                      </v-hover>

                      <!-- 信息列表 -->
                      <div
                          v-for="description in descriptions"
                          :key="description.content"
                      >
                        <v-hover v-slot:default="{ hover }">
                          <v-list-item ripple>
                            <v-list-item-icon>
                              <v-icon
                                  class="mt-1"
                                  :color="hover ? description.color : ''"
                              >mdi-{{ description.icon }}</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                  v-text="description.content"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-hover>
                      </div>

                      <!-- 会员需求等级 -->
                      <v-tooltip
                          :color="vipList[loiData.vip_requirement || 1].color"
                          bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item
                              v-on="on"
                              :style="{
                              background:
                                vipList[loiData.vip_requirement || 1].color,
                            }"
                              dark
                          >
                            <v-list-item-icon>
                              <!-- <v-icon dark v-text="vipList[loiData.vip_requirement].icon"></v-icon> -->
                              {{
                                vipList[
                                loiData.vip_requirement || 1
                                    ].level.substr(0, 2)
                              }}
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title
                                  v-text="
                                  vipList[
                                    loiData.vip_requirement || 1
                                  ].level.substr(2)
                                "
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <span
                            v-if="vipList[loiData.vip_requirement || 1].id == 4"
                        >本资源
                          {{ vipList[loiData.vip_requirement || 1].level }}
                          专享</span
                        >
                        <span v-else
                        >本资源
                          {{ vipList[loiData.vip_requirement || 1].level }}
                          或以上可下载</span
                        >
                      </v-tooltip>
                    </v-list>
                  </v-card>
                </v-col>
                <!-- 标签 -->
                <v-col>
                  <v-card
                      color="white"
                      class="pa-2"
                      :tile="$vuetify.breakpoint.xsOnly"
                  >
                    <v-chip
                        v-for="tag in loiData.tags"
                        :key="tag.id"
                        class="ml-4 my-2"
                        @click="toTagPage(tag.id)"
                    >{{ tag.name }}</v-chip
                    >
                  </v-card>
                  <div
                      class="mt-3"
                      :class="{
                      'text-left': $vuetify.breakpoint.xsOnly,
                      'text-right': $vuetify.breakpoint.mdAndUp,
                    }"
                  >
                    <feedback-dialog
                        :list="feedback_list"
                        :loi_id="loiData.id"
                    ></feedback-dialog>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- bottom block loading -->
          <v-row class="mt-6 mb-2" v-else>
            <v-col cols="11" class="mx-auto">
              <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="10"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>



      </div>
    </div>
  </transition>
</template>

<script>
import {
  fetch_comments,
  comment,
  like_comment,
  delete_comment,
} from "@/api/comment.js";
import {fetch_loi} from "@/api/loi.js";
import { vipList } from "@/config.js";
import { formatTime } from "@/utils/index.js"; //格式化评论日期
import { filterSensitiveWord } from "@/utils/sensitive-word-filter/index.js";

import { fetchCategoryInfoById, subscribe_category } from "@/api/category.js";

import LoiDownloader from "@/components/LoiDownloader.vue"; //下载组件
import FeedbackDialog from "@/components/FeedbackDialog.vue"; //反馈组件
import LoiLiker from "@/components/LoiLiker.vue"; //喜欢组件
import msg from "@/utils/snackbar.js";
import {getVipLevel} from "@/utils/auth";


import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import '@/style/vue-slick-carousel-theme.css'

import LoiOP from "@/components/LoiOP.vue";

export default {
  name: "PostContentPopup",
  components: {
    FeedbackDialog, //反馈组件
    LoiDownloader, //下载组件
    LoiLiker, //喜欢组件

    VueSlickCarousel,
    LoiOP
  },
  data: () => ({
    loiId: null,
    loiData: {
      id: 0,
      loi_title: "",
      loi_description: "",
      category: {
        id: 0,
        name: "",
      },
      tags: [],
      images: null,
      viewer_count: 0,
      comment_count: 0,
      like_count: 0,
      video_count: 0,
      pic_count: 0,
      download_count: 0,
      file_size: "",
      vip_requirement: 0,
      choicest: 0,
      liked: false,
      date: "",
      online_play:0,
      comment_thumbup_count: 0,
    },

    fetch_loading: false,

    vipList: vipList,

    comment_sort: "like_number",
    total_comments: 0, //评论总数
    positivePtg: 0, //推荐百分比

    showSummary: true,
    review_summary: "", //评价等级
    review_summary_color: "", //评价等级颜色

    subscribe_loading: false,
    category_info: {
      name: "",
      cover: "",
      popular: 0,
      subscribed: false,
      following: 0,
      count: 0,
    },

    infiniteId: +new Date(),
    page: 1,
    list: [],

    showComment: false,

    previewImg: "", //预览大图

    comment_loading: false,

    // 反馈
    feedback_list: [
      {
        id: 1,
        text: "无法下载",
        placeholder: "请说明无法下载资源时遇到的具体情况与出现的提示...",
      },
      {
        id: 2,
        text: "下载地址无效",
        placeholder: "请说明您遇到的具体情况...",
      },
      {
        id: 3,
        text: "下载速度过慢",
        placeholder: "请注明您的下载速度、下载工具与网络运营商...",
      },
      {
        id: 4,
        text: "包含不当评论",
        placeholder: "注释...",
      },
      {
        id: 5,
        text: "包含版权侵犯内容",
        placeholder: "说明您的版权被侵犯部分与版权证明...",
      },
      {
        id: 6,
        text: "侵犯我的隐私",
        placeholder: "说明您的隐私被侵犯部分...",
      },
      {
        id: 7,
        text: "其他",
        placeholder:
            "请说明您的问题或分享您的想法，疑问也可提交至 loibus.service@gmail.com",
      },
    ],

    comment: {
      author_point: "",
      content: "",
      anonymousSwitch: false,
    },
    descriptions: [
      {
        color: "primary",
        icon: "movie",
        content: "0 部视频",
      },
      {
        color: "bpink",
        icon: "image",
        content: "0 张图片",
      },
      {
        color: "#FFA726",
        icon: "file",
        content: "0GB",
      },
      {
        color: "#5C6BC0",
        icon: "eye",
        content: "0 查看",
      },
      {
        color: "#66BB6A",
        icon: "cloud-download",
        content: "0 下载",
      },
      {
        color: "like",
        icon: "heart",
        content: "0 喜欢",
      },
      {
        color: "#21243d",
        icon: "calendar-range",
        content: "0000-0-0",
      },
    ],
  }),
  methods: {
    //下载组件下载量增加emit响应事件
    download_increase() {
      this.loiData.download_count++;
      this.descriptions[4].content = `${this.loiData.download_count}\t下载`;
    },
    like_increase() {
      this.loiData.liked = true;
      this.loiData.like_count++;
      this.descriptions[5].content = `${this.loiData.like_count}\t喜欢`;
    },

    like_decrease() {
      this.loiData.liked = false;
      this.loiData.like_count--;
      this.descriptions[5].content = `${this.loiData.like_count}\t喜欢`;
    },
    async handle_comment_like(comment_id, index) {
      if (!this.$store.getters.token) {
        return msg("请先登陆哦~", "primary", "information");
      }

      this.list[index].like_loading = true;

      let response = await like_comment({
        comment_id: comment_id,
      });

      if (response.data == "liked") {
        this.list[index].liked = true;
        this.list[index].like_number++;
      } else {
        this.list[index].liked = false;
        this.list[index].like_number--;
      }

      this.list[index].like_loading = false;
    },
    async postComment() {
      if (!this.$store.getters.token) {
        return msg("请先登陆哦~", "primary", "information");
      }


      const vip_level =  getVipLevel();

      if (vip_level < 1 ){
        return msg("成为会员才可以评论哦~", "primary", "information");
      }


      this.comment_loading = true;


      const last_comment = localStorage.getItem("last_comment");
      const scc_comment = Number(localStorage.getItem("scc_comment"));

      if (last_comment === this.comment.content){
        localStorage.setItem("scc_comment",(scc_comment+1).toString());


        if (scc_comment>1){
          this.comment_loading = false;
          return msg("发出失败，检测到疑似推广内容", "error", "alert-circle", false);
        }
      }else {
        if (scc_comment>0){
          localStorage.setItem("scc_comment",(scc_comment-1).toString());
        }
      }

      let { flag } = filterSensitiveWord(this.comment.content);


      // console.log(status)

      const illegalCharRegex = /[\u{1D400}-\u{1D7FF}\u{1D000}-\u{1D0FF}\u{FF00}-\u{FFEF}\u{1D0A0}-\u{1D0FF}\u{1D434}-\u{1D467}a-zA-Z0-9\s]+.*?[.⠂⠐]+.*?[\u{1D400}-\u{1D7FF}\u{1D000}-\u{1D0FF}\u{FF00}-\u{FFEF}\u{1D0A0}-\u{1D0FF}\u{1D434}-\u{1D467}a-zA-Z0-9\s]+/gu;


      if (flag || this.comment.content.includes("+") || this.comment.content.includes("：") || illegalCharRegex.test(this.comment.content) || /[\u2000-\u200F\u2028-\u202F\u205F\u3000]/.test(this.comment.content)) {
        this.comment_loading = false;
        return msg("发出失败，包含非法词汇", "error", "alert-circle", false);
      }


      localStorage.setItem("last_comment",this.comment.content);


      try {
        let response = await comment({
          loi_id: this.loiId,
          author_point: this.comment.author_point == "👍 推荐" ? 1 : 0,
          comment_content: this.comment.content,
        });

        this.page = 1;
        this.list = [];
        this.infiniteId += 1;
        this.total_comments += 1;

        //评论后更新评价等级
        if (this.comment.author_point == "👍 推荐")
          this.loiData.comment_thumbup_count++;

        this.positivePtg = (
            (this.loiData.comment_thumbup_count / this.total_comments) *
            100
        ).toFixed(0);
        this.review_summary = this.calculateReviewSummary();
        msg(response.data, "success", "check", false);
        this.clearComment();
      } catch (error) {
        msg(error.err_msg, "error", "information", false);
      }
      this.comment_loading = false;
    },

    hideOverlay_dir() {
      //清空状态
      this.page = 1;
      this.list = [];
      this.previewImg = "";
      this.images = null;
      this.loiData = {
        id: 0,
        loi_title: "",
        loi_description: "",
        category: {
          id: 0,
          name: "",
        },
      };
      this.clearComment();
      this.$emit("hideLoiModal");

      window.history.back();
    },

    hideOverlay(event) {
      let content_dom = document.getElementById("overlay-content");
      // let prevBtn_dom = document.getElementById("prevBtn");
      // let nextBtn_dom = document.getElementById("nextBtn");

      if (content_dom) {
        if (
            !content_dom.contains(event.target)
            // !prevBtn_dom.contains(event.target) &&
            // !nextBtn_dom.contains(event.target)
        ) {
          //清空状态
          this.page = 1;
          this.list = [];
          this.previewImg = "";
          this.images = null;
          this.loiData = {
            id: 0,
            loi_title: "",
            loi_description: "",
            category: {
              id: 0,
              name: "",
            },
          };
          this.clearComment();
          this.$emit("hideLoiModal");
          window.history.back();
        }
      }
    },
    clearComment() {
      this.showComment = false;
      this.comment.content = "";
      this.comment.author_point = 0;
    },
    goBack() {
      this.page = 1;
      this.list = [];

      this.loiData.images = null;

      this.clearComment();
      this.$emit("hideLoiModal");

      document.documentElement.style.overflowY = "scroll";
      window.removeEventListener("popstate", this.goBack, false);
    },
    async infiniteHandler($state) {
      try {
        let response = await fetch_comments(
            this.page,
            this.comment_sort,
            this.loiId
        );
        // 总体评价
        this.total_comments = response.pagination.total;
        this.positivePtg = (
            (this.loiData.comment_thumbup_count / this.total_comments) *
            100
        ).toFixed(0);
        this.review_summary = this.calculateReviewSummary();

        if (response.data.length) {
          this.page += 1;
          this.list.push(...response.data);

          // console.log(this.list)

          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (error) {
        msg("评论获取失败", "error", "information", false);
      }
    },
    calculateReviewSummary() {
      let positivePtg = this.positivePtg;

      //若评价总数小于2则不显示评价等级
      if (this.total_comments < 2) return (this.showSummary = false);

      this.showSummary = true;

      if (positivePtg >= 95 && this.total_comments > 5) {
        this.review_summary_color = "#039BE5";
        return "😍 好评如潮";
      } else if (positivePtg >= 90 && this.total_comments > 4) {
        this.review_summary_color = "#42A5F5";
        return "🥰 特别好评";
      } else if (positivePtg >= 90) {
        this.review_summary_color = "#42A5F5";
        return "🥰 好评";
      } else if (positivePtg >= 70) {
        this.review_summary_color = "#29B6F6";
        return "👍 多半好评";
      } else if (positivePtg >= 40) {
        this.review_summary_color = "#F4511E";
        return "😶 褒贬不一";
      } else if (positivePtg >= 20 && this.total_comments > 4) {
        this.review_summary_color = "#FF6F00";
        return "👎 多半差评";
      } else if (positivePtg >= 10 && this.total_comments > 5) {
        this.review_summary_color = "#FF4081";
        return "🤡 特别差评";
      } else if (positivePtg >= 0 && this.total_comments > 6) {
        this.review_summary_color = "#FF1744";
        return "🤮 差评如潮";
      } else if (positivePtg >= 20) {
        this.review_summary_color = "#FF6F00";
        return "👎 差评";
      }
    },
    sortByPopular() {
      this.comment_sort = "like_number";
      this.page = 1;
      this.list = [];
      this.infiniteId += 1;
    },
    sortByLatest() {
      this.comment_sort = "date";
      this.page = 1;
      this.list = [];
      this.infiniteId += 1;
    },
    handleToPage() {
      let event = window.event || arguments.callee.caller.arguments[0];
      let content_dom = [...document.querySelectorAll(".unresponsive-mark")];
      if (content_dom) {
        if (!content_dom.includes(event.target)) {

          document.documentElement.style.overflowY = "scroll";

          this.$router.push({
            name: "category-page",
            params: { id: this.loiData.category.id },
          })

        }
      }
    },
    toTagPage(tag_id){
      document.documentElement.style.overflowY = "scroll";
      this.$router.push({
        name: "tag-page",
        params: { id: tag_id },
      })

    },

    formatTime(date) {
      return formatTime(Date.parse(date));
    },
    async getCategoryInfo() {
      let response = await fetchCategoryInfoById(this.loiData.category.id);
      this.category_info = response.data;
      // window.console.log(response.data);
    },
    async handle_subscribe() {
      if (!this.$store.getters.token) {
        return msg("请先登陆后订阅哦~", "primary", "information");
      }
      this.subscribe_loading = true;

      await subscribe_category({
        category_id: this.loiData.category.id,
      });

      this.category_info.subscribed = !this.category_info.subscribed;

      if (this.category_info.subscribed) {
        this.category_info.following++;
        msg("订阅成功!", "success", "check", false);
      } else {
        this.category_info.following--;
        msg("已取消订阅", "warning", "information", false);
      }

      // window.console.log(response);

      this.subscribe_loading = false;
    },
    async handle_deleteComment(index) {
      try {
        let response = await delete_comment({
          loi_id: this.loiData.id,
        });

        this.$delete(this.list, index);
        msg(response.data, "success", "check");
      } catch (error) {
        window.console.log(error);
        msg("请求异常", "error", "information", false);
      }
    },
    async fetch_Loi(loi_id) {
      //清空状态
      this.page = 1;
      this.list = [];
      this.previewImg = "";
      this.images = null;
      this.loiData = {
        id: 0,
        loi_title: "",
        loi_description: "",
        open_key: "",
        category: {
          id: 0,
          name: "",
        },
      };

      this.loiId = loi_id;

      if (window.history && window.history.pushState) {
        window.addEventListener("popstate", this.goBack, false);
      }

      this.fetch_loading = false;
      history.pushState({}, null, `/lois/${this.loiId}`);
      document.documentElement.style.overflowY = "hidden";

      try {
        let { data } = await fetch_loi(this.loiId);

        this.loiData = data;

        this.previewImg = this.loiData.images[0];
        this.descriptions[0].content = `${this.loiData.video_count}\t部视频`;
        this.descriptions[1].content = `${this.loiData.pic_count}\t张图片`;
        this.descriptions[2].content = `${this.loiData.file_size}`;
        this.descriptions[3].content = `${this.loiData.viewer_count + 1}\t查看`;
        this.descriptions[4].content = `${this.loiData.download_count}\t下载`;
        this.descriptions[5].content = `${this.loiData.like_count}\t喜欢`;
        this.descriptions[6].content = `${this.loiData.date.substr(0, 10)}`;
      } catch (error) {
        msg("加载失败,请刷新页面重试", "error", "information", false);
      }

      this.fetch_loading = true;
    },
  },
  computed: {
    showArrows() {
      // 当断点为 xsOnly 时返回 false，否则返回 true
      return !this.$vuetify.breakpoint.xsOnly;
    },
    downloaderStyle() {
      if (this.loiData.online_play === 1) {
        return "position: relative; left: 20px;";
      }
      return ""; // 当不等于1时，返回空字符串，取消style
    },
    downloaderTheme() {
      return this.loiData.online_play === 1 ? 2 : 1;
    },
    downloaderMdCol_title(){
      return this.loiData.online_play === 1 ? 8 : 9;
    },
    downloaderMdCol_dl(){
      return this.loiData.online_play === 1 ? 2 : 1;
    }
  },
  // destroyed() {
  //   document.documentElement.style.overflowY = "scroll";
  //   window.removeEventListener("popstate", this.goBack, false);
  // },
  // mounted() {
  //   if (window.history && window.history.pushState) {
  //     window.addEventListener("popstate", this.goBack, false);
  //   }
  // }
};
</script>

<style scoped>
.post-overlay {
  background: rgba(30, 30, 30, 0.9);
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;

  overflow-x: hidden;

  box-sizing: border-box;
  transition: all 0.07s ease-in;
}

@media only screen and (max-width: 959px) {
  .post-overlay {
    overflow-y: scroll;
  }
}

.overlay-content {
  position: absolute;
  top: 10px;
  left: 50%;
  width: 860px;
  box-sizing: border-box;
  margin-left: -400px;
  background: #f4f4f4;
  border-radius: 6px;
  transform: translateY(10px);
  transition: all 0.1s ease-in;
}

.overlay-content:after {
  position: absolute;
  content: "";
  bottom: -20px;
  height: 20px;
  width: 1px;
}

@media only screen and (max-width: 959px) {
  .overlay-content {
    left: auto;
    top: -10px;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    padding: 0px;
    border-radius: 0;
  }

  .overlay-content:after {
    display: none;
  }
}

@media only screen and (width: 1024px) and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .overlay-content {
    width: 902px;
    margin-left: -451px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.Overlay-closeBtn:hover {
  opacity: 0.5;
}

@media (min-width: 1024px) {
  .Overlay-closeBtn {
    position: fixed;
    right: 8px;
    top: 8px;
    padding: 5px;
    cursor: pointer;
    transform: translateX(-17px);
    transition: all 0.1s linear;
  }
}

.thumb {
  cursor: pointer;
}

.thumb_mr:not(:last-child) {
  margin-right: 10px !important;
}

.thumb:hover {
  opacity: 0.75;
}

.PostInfo {
  background-color: #fafafa;
  padding: 90px 100px;
  width: 100%;
}

</style>
